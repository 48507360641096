<template>
  <div class="mt-3">
    <h1 class="mb-3">
      {{ $t('tos.t') }}
    </h1>
    <b-row>
      <b-col>
        <h5>{{ $t('tos.hov') }}</h5>
        <p class="text-justify">
          <i18n path="tos.ovwtxt">
            <template #linkimpr><b-link to="imprint">{{ $t('tos.ovwtxtl') }}</b-link></template>
            <template #linkdata><b-link to="contentpolicy">{{ $t('tos.ovwtxtd') }}</b-link></template>
          </i18n>
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>{{ $t('tos.heg') }}</h5>
        <b-list-group class="pb-3">
          <b-list-group-item v-for="(it, idx) in entries" :key="`l.`+idx" :id="it.h">
            <h6 class="font-weight-bold">{{ $t('tos.h' + idx, [idx+1]) }}</h6>
            <template v-for="(sit, sidx) in it.s">
              <p class="text-justify" :key="`p.`+idx+`.`+sidx" v-if="sit.l===false">
                {{ $t('tos.c' + idx) }}
              </p>
              <template v-else>
                <ol v-for="(i,) in sit.e" :key="`ol.`+idx+`.`+sidx+`.`+i" class="text-justify">
                  <li :key="`li.`+idx+`.`+i" :s="idx+1" :x="i">
                    {{ $t(['tos.c',idx,i].join('')) }}
                    <ul v-if="Array.isArray(sit.s) && sit.s[i-1]!=0" class="pl-0">
                      <li v-for="(ii, ) in sit.s[i-1]" :key="`ul.`+idx+`.`+sidx+`.`+i+`.`+ii">{{ $t(['tos.c',idx,i,ii].join('')) }}</li>
                    </ul>
                  </li>
                </ol>
              </template>
            </template>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>{{ $t('tos.hvs') }}</h5>
        <p class="text-justify">
          {{ $t('tos.lv', ['1']) }}
          <i18n path="tos.lvt">
            <template #date>{{ $d(new Date(2021,4,1), 'ds') }}</template>
          </i18n>
        </p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      entries: [
        {h: 'applicability', s: [{l: true, e: 3}]},
        {h: 'terms', s: [{l: false},{l: true, e: 3, s: [7]}]},
        {h: 'responsibilities', s: [{l: true, e: 5, s: [0,0,0,4,0]}]},
        {h: 'payments', s: [{l: true, e: 9}]},
        {h: 'warranty', s: [{l: false}]},
        {h: 'liability', s: [{l: true, e: 4}]},
        {h: 'cancel', s: [{l: false},{l: true, e: 5, s: [4,2,0,0,7]}]},
      ],
    };
  },
}
</script>

<style scoped>
ol {
  list-style: none;
}
ol>li {
  position: relative;
}
ol>li:before {
  content: attr(s) "." attr(x) ". ";
  position: absolute;
  left: -2em;
}
</style>